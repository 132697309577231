import { DefaultUrlSerializer, UrlSerializer, UrlTree } from "@angular/router";

export class CustomUrlSerializer implements UrlSerializer {
  parse(url: any): UrlTree {
    const serializer = new DefaultUrlSerializer();
    return serializer.parse(url);
  }

  serialize(tree: UrlTree): any {
    const serializer = new DefaultUrlSerializer();
    let path = serializer.serialize(tree);

    if (tree.fragment) {
      path = path.replace(/\/$/, "");
    } else {
      if (path === "/") {
        path = "";
      } else if (path.startsWith("/?")) {
        path = path.replace(/^./, "");
      }

      const pathArr = path.split("?");
      pathArr[0] += "/";
      const joinSeparator = pathArr.length > 1 ? "?" : "";
      path = pathArr.join(joinSeparator);
    }

    return path;
  }
}
