import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ContentApiService } from "../../services/api/content-api.service";
import { loadResults, loadResultsSuccess } from "./search.actions";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable()
export class SearchEffects {
  searchResultPage: string | undefined;
  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadResults),
      switchMap((action: any) =>
        this.contentApiService
          .search(action.query, action.collection, action.page)
          .pipe(
            map((search) => {
              const url = this.router
                .createUrlTree([], {
                  relativeTo: this.route,
                  queryParams: {
                    q: action.query,
                    collection: action.collection,
                    page: action.page
                  }
                })
                .toString();

              this.location.go(url);

              return loadResultsSuccess({
                search: search,
                searchTerm: action.query,
                collection: action.collection,
                page: action.page,
                filters: action.filters,
                totalMatching: search.body.totalMatching
              });
            }),
            catchError(() => of({ type: "[Search API] Search Loaded Error" }))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private contentApiService: ContentApiService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {}
}
