import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "healthcheck",
    loadChildren: () =>
      import("./components/utils/health-check/healthcheck.routes").then(
        (routes) => routes.routes
      )
  },
  {
    path: "**",
    loadChildren: () =>
      import("./components/page/page.routes").then((routes) => routes.routes)
  }
];
