import { createReducer, on } from "@ngrx/store";
import {
  loadResults,
  loadResultsSuccess,
  setCollectionName,
  setSearchResultPage,
  setSearchTerm,
  updateTotalMatching
} from "./search.actions";

// this interface is here to avoid TS error when using a string to access object keys
// this error would have appeared in the text block when dynamically loading the content area layout
interface IObjectKeys {
  [key: string]: any;
}

export interface SearchState extends IObjectKeys {
  searchResults: any;
  totalMatching: number;
  trackingId: string;
  searchTerm: string;
  loading: boolean;
  searchResultPage: string;
  collectionName: string;
}

export const initialState: SearchState = {
  searchResults: null,
  totalMatching: 0,
  trackingId: "",
  searchTerm: "",
  loading: false,
  searchResultPage: "",
  collectionName: ""
};

export const searchReducer = createReducer(
  initialState,
  on(loadResults, (state) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(loadResultsSuccess, (state, { search, searchTerm, collection, page }) => {
    const body = search?.body || {};
    return {
      ...state,
      searchResults: body.results || [],
      totalMatching: body.totalMatching || 0,
      trackingId: body.trackingId || '',
      searchTerm: searchTerm,
      collection: collection,
      page: page,
      loading: false
    };
  }),
  on(setSearchTerm, (state, { searchTerm }) => {
    return {
      ...state,
      searchTerm: searchTerm
    };
  }),
  on(setSearchResultPage, (state, { searchResultPage }) => {
    return {
      ...state,
      searchResultPage: searchResultPage
    };
  }),
  on(setCollectionName, (state, { collectionName }) => {
    return {
      ...state,
      collectionName: collectionName
    };
  }),
  on(updateTotalMatching, (state, { total }) => ({
    ...state,
    totalMatching: total
  }))
);
